$kendo-grid-padding-x: 0;
$kendo-grid-padding-y: 0.5rem;
$kendo-grid-header-padding-x: 0;
$kendo-grid-header-padding-y: 0.25rem;
$kendo-grid-filter-cell-padding-x: 0;
$kendo-grid-filter-cell-padding-y: 0.25rem;
$kendo-grid-cell-padding-x: $kendo-grid-padding-x;
$kendo-grid-cell-padding-y: $kendo-grid-padding-y;
$kendo-grid-cell-horizontal-border-width: to-rem(1px, $base-line-height);
$kendo-grid-cell-vertical-border-width: 0;
$kendo-grid-border-width: 0;
$kendo-grid-border: color('lightgray');
$kendo-grid-alt-bg: transparent;
$kendo-grid-hover-bg: color('smoke');
$kendo-grid-selected-bg: #f4f6f8;
$kendo-grid-sorted-icon-spacing: 0;