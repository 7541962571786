$kendo-font-size: $base-font-size;
$kendo-font-size-xs: 10px;
$kendo-font-size-sm: 14px;
$kendo-font-size-md: 16px;
$kendo-font-size-lg: 18px;
$kendo-font-size-xl: 20px;
$kendo-font-family: $base-font-family;
$kendo-font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Roboto Mono", "Ubuntu Mono", "Lucida Console", "Courier New", monospace;
$kendo-line-height: math.div($base-line-height, $base-font-size);
$kendo-line-height-xs: 1;
$kendo-line-height-sm: 1.2;
$kendo-line-height-md: $kendo-line-height;
$kendo-line-height-lg: 1.5;
$kendo-line-height-em: $kendo-line-height * 1em;