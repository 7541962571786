$kendo-input-border-width: to-rem(1px, $base-line-height);
$kendo-input-border-height: $kendo-input-border-width * 2;
$kendo-input-border-radius: 0.75rem;
$kendo-input-padding-x: $kendo-padding-x;
$kendo-input-padding-y: $kendo-padding-y;
$kendo-input-font-size: map-get($font-sizes, 's');
$kendo-input-line-height: 1.5;
$kendo-input-line-height-em: $kendo-input-line-height * 1em;
$kendo-input-sm-padding-y: ($kendo-input-padding-y * 0.5);
$kendo-input-sm-line-height: $kendo-line-height-sm;
$kendo-input-lg-padding-y: ($kendo-input-padding-y * 1.5);
$kendo-input-lg-line-height: $kendo-line-height-lg;
$kendo-input-calc-height: $kendo-input-line-height + $kendo-input-padding-y * 2;
$kendo-input-calc-height-sm: $kendo-input-sm-line-height + $kendo-input-sm-padding-y * 2 + $kendo-input-border-height;
$kendo-input-calc-height-lg: $kendo-input-lg-line-height + $kendo-input-lg-padding-y * 2 + $kendo-input-border-height;
$kendo-input-inner-calc-height: $kendo-input-line-height + $kendo-input-padding-y * 2;
$kendo-input-inner-calc-height-sm: $kendo-input-sm-line-height + $kendo-input-sm-padding-y * 2;
$kendo-input-inner-calc-height-lg: $kendo-input-lg-line-height + $kendo-input-lg-padding-y * 2;
$kendo-input-icon-width: to-rem($kendo-icon-size, $base-line-height) + $kendo-input-padding-y * 2;
$kendo-input-icon-height: to-rem($kendo-input-font-size, $base-line-height) + $kendo-input-padding-y * 2;
$kendo-input-adornment-height: to-rem($kendo-input-font-size, $base-line-height) + $kendo-input-padding-y * 2;
$kendo-input-adornment-width: $kendo-input-adornment-height;
$kendo-input-text: $kendo-component-text;
$kendo-input-border: color('primary');
$kendo-input-hovered-border: color('primary');
$kendo-input-hovered-shadow: none;
$kendo-input-focused-border: $kendo-input-hovered-border;
$kendo-input-focused-shadow: none;
$kendo-input-placeholder-text: rgba( $kendo-input-text, .5);
$kendo-input-selected-bg: color('primary');
$kendo-input-hover-shadow: none;
$kendo-input-hover-border: color('primary');
$kendo-input-padding-y: .25rem;
$kendo-input-padding-x: .5rem;