$kendo-upload-border-width: 0;
$kendo-upload-line-height: map-get($font-sizes, 's');
$kendo-upload-max-height: unset;
$kendo-upload-border: transparent;
$kendo-upload-dropzone-padding-x: 1rem;
$kendo-upload-dropzone-padding-y: 1rem;
$kendo-upload-dropzone-bg: transparent;
$kendo-upload-dropzone-text: $kendo-base-text;
$kendo-upload-dropzone-text-opacity: 1;
$kendo-upload-dropzone-border: color('lightgray');
$kendo-upload-dropzone-hover-bg: color('primary', 'alpha', 90);
$kendo-upload-item-padding-x: 0px;
$kendo-upload-item-padding-y: 0px;
$kendo-upload-icon-spacing: $kendo-icon-spacing;