$kendo-panelbar-line-height: $base-line-height;
$kendo-panelbar-border-width: 0;
$kendo-panelbar-header-padding-x: 0;
$kendo-panelbar-border: color('lightgray');
$kendo-panelbar-header-bg: transparent;
$kendo-panelbar-header-text: color('secondary');
$kendo-panelbar-header-border: color('lightgray');
$kendo-panelbar-header-hovered-bg: transparent;
$kendo-panelbar-header-hovered-text: color('secondary');
$kendo-panelbar-header-hovered-border: null;
$kendo-panelbar-header-selected-bg: transparent;
$kendo-panelbar-header-selected-text: color('secondary');
$kendo-panelbar-header-selected-border: color('lightgray');
$kendo-panelbar-header-focused-shadow: none;
$kendo-panelbar-item-hovered-bg: transparent;
$kendo-panelbar-item-hovered-text: null;
$kendo-panelbar-item-hovered-border: null;
$kendo-panelbar-item-selected-bg: transparent;
$kendo-panelbar-item-selected-text: inherit;
$kendo-panelbar-item-selected-border: null;
$kendo-panelbar-item-focused-shadow: none;
