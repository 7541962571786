@use "@progress/kendo-theme-core/scss/functions/index.import.scss" as *;

$kendo-icon-size: 16px;
$kendo-icon-size-lg: 32px;
$kendo-icon-spacing: 4px;
$kendo-padding-x: 0.5rem;
$kendo-padding-y: $kendo-padding-x * 0.5;
$border-radius: $default-border-radius;
$border-radius-sm: $border-radius * 0.5;
$border-radius-lg: $border-radius * 2;
$bg-color: color('white');
$text-color: color('text');
$kendo-base-bg: color('white'); // $kendo-color-secondary
$kendo-base-text: color('tertiary');
$base-border: color('secondary');
$kendo-hover-bg: color('smoke');
$kendo-hover-text: $kendo-base-text;
$kendo-selectedbg: k-color(primary);
$kendo-selectedtext: color('white');
$kendo-body-bg: $bg-color;
$kendo-body-text: $text-color;
$kendo-component-bg: $kendo-body-bg;
$kendo-component-text: $kendo-body-text;
$kendo-component-border: color('primary');
$kendo-color-primary: color('primary');
$dropdownlist-selected-background: color('primary');
$kendo-list-item-selected-bg: color('primary');
$kendo-list-item-hover-bg: color('primary') !important;
$kendo-list-item-focus-bg: color('primary') !important;